<template>
  <!-- 查看数据质量信息 -->
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dataQuality' }">数据清洗标准</el-breadcrumb-item>
      <el-breadcrumb-item >查看</el-breadcrumb-item>
      <el-breadcrumb-item >查看</el-breadcrumb-item>
    </el-breadcrumb>
    <span style="font-size: 25px;">清洗标准信息：</span>
    <el-card>
      <template>
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column label="完整性">
            <el-table-column prop="point1" label="数据项" header-align="center" align="center" width="150">
            </el-table-column>
            <el-table-column prop="isExisted" label="数据是否存在" header-align="center" align="center" width="150">
            </el-table-column>
          </el-table-column>
          <el-table-column label="完整性">
            <el-table-column prop="point2" label="数据项" header-align="center" align="center" width="150">
            </el-table-column>
            <el-table-column prop="range" label="范围" header-align="center" align="center" width="150">
            </el-table-column>
            <el-table-column prop="unit" label="单位" header-align="center" align="center" width="150">
            </el-table-column>
            <el-table-column prop="rationality" label="数据合理性" header-align="center" align="center" width="150">
            </el-table-column>
            <el-table-column prop="storage_len" label="数据存储长度" header-align="center" align="center" width="150">
            </el-table-column>
          </el-table-column>
          <el-table-column label="时效性">
            <el-table-column prop="frequency" label="采集频率" header-align="center" align="center" width="150">
            </el-table-column>
          </el-table-column>
        </el-table>
      </template>


      <!-- 分页区域 -->
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[1, 2, 5, 10]" :page-size="1" layout="total, sizes, prev, pager, next, jumper" :total="5">
      </el-pagination> -->
    </el-card>

    <el-form ref="modifyForm" :model="addForm" label-width="110px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="新版本号" prop="system">
            <el-input style="width: 220px;" v-model="addForm.version" placeholder="请输入版本号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="修改说明" prop="address">
            <el-input style="width: 220px;" v-model="addForm.modifyExplain" placeholder="请填写修改说明"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input style="width: 220px;" v-model="addForm.currOperator" placeholder="当前登陆人员：张三"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-input style="width: 220px;" v-model="addForm.time" placeholder="当前时间：2021.02.05 12：05"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
export default {
  name: "checkSystem",
  data() {
    return {
      visible: false,
      value: '',
      tableData: [{
        point1: '电机绕组A相温度',
        isExisted: '是',
        point2: '电机绕组A相温度',
        range: '上阈值120.0℃',
        unit: '℃',
        rationality: '合理',
        storage_len: '50',
        frequency: '5s'
      }, {
        point1: '电机绕组B相温度',
        isExisted: '是',
        point2: '电机绕组B相温度',
        range: '上阈值120.0℃',
        unit: '℃',
        rationality: '合理',
        storage_len: '50',
        frequency: '5s'
      }, {
        point1: '电机绕组C相温度',
        isExisted: '是',
        point2: '电机绕组C相温度',
        range: '上阈值120.0℃',
        unit: '℃',
        rationality: '合理',
        storage_len: '50',
        frequency: '5s'
      }, {
        point1: '电机前轴温度',
        isExisted: '是',
        point2: '电机前轴温度',
        range: '上阈值120.0℃',
        unit: '℃',
        rationality: '合理',
        storage_len: '50',
        frequency: '5s'
      }, {
        point1: '电机后轴温度',
        isExisted: '是',
        point2: '电机后轴温度',
        range: '上阈值85.0℃',
        unit: '℃',
        rationality: '合理',
        storage_len: '50',
        frequency: '5s'
      }, {
        point1: '电机振动1',
        isExisted: '是',
        point2: '电机振动1',
        range: '上阈值120.0℃',
        unit: 'mm/s',
        rationality: '合理',
        storage_len: '50',
        frequency: '5s'
      }, {
        point1: '电机振动2',
        isExisted: '是',
        point2: '电机振动1',
        range: '上阈值120.0℃',
        unit: 'mm/s',
        rationality: '合理',
        storage_len: '50',
        frequency: '5s'
      }],

      currentPage1: 1,
      currentPage2: 2,
      currentPage3: 3,
      currentPage4: 4,
      addForm: {
        // this.editForm.phone = row.phone
        version: '',
        modifyExplain: '',
        currOperator: '',
        time: ''

      }
    }
  },
  methods: {

    // init(id) {
    //     this.visible = true;
    // }

  }
}
</script>


<style scoped>

</style>
